import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Img from "gatsby-image"

import "../styles/style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
// You can delete this file if you're not using it
const AboutPage = (data) => (
  <Layout pageInfo={{ pageName: "about" }}>
    <SEO title="About" keywords={[]} />
    <Container className="about">
      <Row>
        <Col lg={6} className="d-flex align-content-around flex-wrap our-philosophy">
          <div>
            <h1>Our Philosophy</h1>
            <p>Accelerate specializes in publishing indie titles and focuses on working together with our studio
              partners. We only select a limited number of games to publish because we are dedicated to each and every title.</p>
            <p> We understand independent studios have worked tirelessly over the course of years to build their games
              and it is up to us to add value and continue the process through launch, live operations, sequels,
              franchises and beyond.</p>
            <p> Publishing is a people driven business and we stay true to it.</p>
          </div>
        </Col>
        <Col lg={6} className="d-flex align-content-around flex-wrap hide">
          <Img fluid={data.data.controllers.childImageSharp.fluid} alt="Accelerate controllers" className="hide controllers" fadeIn={false}/>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage;

export const query = graphql`
  query {
    controllers: file(relativePath: { eq: "controllers.png" }) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid
          }
      }
    }
  }
`